import logo from "../assets/Logo1.jpg";
import carImage from "../assets/Car_1.jpg";

const data_eng = {
  next_lang: "Русский",
  areas: [
    "North York",
    "Richmond Hill",
    "Mississauga",
    "Brampton",
    "Etobicoke",
  ],
  business_hours: ["Business Hours:", "Monday - Sunday,", "7:00 AM - 10:00 PM"],
  languages: [
    "We teach in: ",
    "Russian, Ukrainian, Polish, Hebrew, Slovak, Hungarian",
  ],

  promotions: [
    "Lessons on new electric cars",
    "Over 30 years of teaching experience",
  ],
  services_title: "OUR SERVICES",
  services: [
    "Lessons for beginning and skilled drivers",
    "Support and preparation for theoretical examination",
    "Reception of the certificate lowering the insurance and term of expectation of examination on driving",
    "Individual lessons of 60-90 minutes",
    "Lessons on Highways - 90 minutes with display of routes on the Highway",
    "Display of examination routes (on day of examination)",
    "Time of examination in the shortest term depends on the readiness of the student",
    "The help in purchase of the car and the insurance under reasonable prices",
  ],
  contacts: "CONTACT US",
  addresses: [
    {
      address: "6174 Yonge St, Unit 203",
      city: "Toronto",
      province: "ON",
      zipCode: "M2M 3X1",
    },
    {
      address: "560 Sheppard Ave W",
      city: "North York",
      province: "ON",
      zipCode: "M3H 2R9",
    },
  ],
  phones_title: "Call Us:",
  phones: ["+1 (416) 727-6997", "+1 (905) 709-1044"],
  email: "rigdal@rogers.com",
  reviews: [
    "We have only 5-star reviews on Google Reviews",
    "View reviews",
    "Write a review",
  ],
  url: [
    "https://www.google.com/search?hl=en-NL&gl=nl&q=Roman+Driving+School,+6174+Yonge+St+Unit+203,+Toronto,+ON+M2M+3X1,+Canada&ludocid=17140024289349828605&lsig=AB86z5WPjOGY9GR4k692a370jaGB&hl=en&gl=NL&sa=X&ved=2ahUKEwiZ8JrTysmEAxUb6wIHHa__AkgQ3PALegQIMxAV#lrd=0x882b3277717da71d:0xeddd994788437ffd,1,,,,",
    "https://www.google.com/search?hl=en-NL&gl=nl&q=Roman+Driving+School,+6174+Yonge+St+Unit+203,+Toronto,+ON+M2M+3X1,+Canada&ludocid=17140024289349828605&lsig=AB86z5WPjOGY9GR4k692a370jaGB&hl=en&gl=NL&sa=X&ved=2ahUKEwiZ8JrTysmEAxUb6wIHHa__AkgQ3PALegQIMxAV#lrd=0x882b3277717da71d:0xeddd994788437ffd,3,,,,",
  ],
};

const data_rus = {
  next_lang: "English",
  areas: [
    "North York",
    "Richmond Hill",
    "Mississauga",
    "Brampton",
    "Etobicoke",
  ],
  business_hours: [
    "Время работы:",
    "Понедельник - Воскресенье,",
    "7:00 - 22:00",
  ],
  languages: [
    "Мы учим на: ",
    "русском, английском, украинском, польском, иврите, словацком и венгерском языках",
  ],

  promotions: [
    "Занятия на новых электрических автомобилях",
    "Опыт преподавания более 30 лет",
  ],

  services_title: "НАШИ УСЛУГИ",
  services: [
    "Уроки для начинающих и опытных водителей",
    "Сопровождение и подготовка к теоритическому экзамену",
    "Получение сертификата, снижающего страховку и срок ожидания экзамена по вождению",
    "Индивидуальные уроки 60-90 мин.",
    "Уроки по хайвэям - 90 мин. с показом маршрутов хайвэйной части",
    "Показ экзаменационных маршрутов (в день экзамена)",
    "Назначение экзамена в кратчайший срок по мере готовности студента",
    "Помощь в приобретении автомобиля и страховки по умеренным ценам",
  ],
  contacts: "КОНТАКТНАЯ ИНФОРМАЦИЯ",
  addresses: [
    {
      address: "6174 Yonge St Unit 203",
      city: "Toronto",
      province: "ON",
      zipCode: "M2M 3X1",
    },
    {
      address: "560 Sheppard Ave W",
      city: "North York",
      province: "ON",
      zipCode: "M3H 2R9",
    },
  ],
  phones_title: "Наши телефоны:",
  phones: ["+1 (416) 727-6997", "+1 (905) 709-1044"],
  email: "rigdal@rogers.com",
  reviews: [
    "У нас только пятизвёздочные отзывы на Google Отзывы",
    "Посмотреть отзывы",
    "Добавить отзыв",
  ],
  url: [
    "https://www.google.com/search?hl=en-NL&gl=nl&q=Roman+Driving+School,+6174+Yonge+St+Unit+203,+Toronto,+ON+M2M+3X1,+Canada&ludocid=17140024289349828605&lsig=AB86z5WPjOGY9GR4k692a370jaGB&hl=en&gl=NL&sa=X&ved=2ahUKEwiZ8JrTysmEAxUb6wIHHa__AkgQ3PALegQIMxAV#lrd=0x882b3277717da71d:0xeddd994788437ffd,1,,,,",
    "https://www.google.com/search?hl=en-NL&gl=nl&q=Roman+Driving+School,+6174+Yonge+St+Unit+203,+Toronto,+ON+M2M+3X1,+Canada&ludocid=17140024289349828605&lsig=AB86z5WPjOGY9GR4k692a370jaGB&hl=en&gl=NL&sa=X&ved=2ahUKEwiZ8JrTysmEAxUb6wIHHa__AkgQ3PALegQIMxAV#lrd=0x882b3277717da71d:0xeddd994788437ffd,3,,,,",
  ],
};

const links_data = [
  {
    home: "Home",
    prices: "Prices",
    aboutUs: "About Us",
  },
  {
    home: "Главная",
    prices: "Цены",
    aboutUs: "О Нас",
  },
];

let exp = { logo, carImage, data_eng, data_rus, links_data };

export default exp;
