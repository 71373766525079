import React, { useContext } from "react";
import data from "../data/data.js";
import { LangContext } from "../App";
import "./Reviews.css";

const Reviews = () => {
  const { lang } = useContext(LangContext);
  let temp_data,
    temp_reviews,
    temp_writeReview,
    temp_url_reviews,
    temp_url_writeReview;

  if (lang) {
    temp_data = data.data_eng.reviews[0];
    temp_reviews = data.data_eng.reviews[1];
    temp_writeReview = data.data_eng.reviews[2];
    temp_url_reviews = data.data_eng.url[0];
    temp_url_writeReview = data.data_eng.url[1];
  } else {
    temp_data = data.data_rus.reviews[0];
    temp_reviews = data.data_rus.reviews[1];
    temp_writeReview = data.data_rus.reviews[2];
    temp_url_reviews = data.data_rus.url[0];
    temp_url_writeReview = data.data_rus.url[1];
  }

  const openNewTab = function (url) {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <>
      {temp_data && temp_reviews && temp_writeReview && (
        <div className="reviews__area">
          <div className="reviews__area-content">
            <div className="reviews__area-content-title">{temp_data}</div>
            <div className="reviews__review-btn-area">
              <div className="reviews__btn-div">
                <button
                  className="reviews__btn"
                  onClick={() => openNewTab(temp_url_reviews)}
                >
                  {temp_reviews}
                </button>
              </div>
              <div className="reviews__btn-div">
                <button
                  className="reviews__btn"
                  onClick={() => openNewTab(temp_url_writeReview)}
                >
                  {temp_writeReview}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Reviews;
