const prices_G2_G = {
  g2: 75,
  g: 80,
};

const prices_eng = [
  {
    service: "G2 License Road Test Preparation",
    description1:
      "Includes practical driving lessons and personalized feedback.",
    description2:
      "Practice of fundamental maneuvers and essential city driving skills such as parallel parking, three-point turns, reversing, parking, and obeying traffic signals and posted speed limits",
    currency: "$",
    price: prices_G2_G.g2,
    hour: "per hour",
  },
  {
    service: "G License Road Test Preparation",
    description1:
      "Includes practical driving lessons and personalized feedback.",
    description2:
      "Practice of city driving and highway driving including merging, lane changes, and navigating high-speed traffic",
    currency: "$",
    price: prices_G2_G.g,
    hour: "per hour",
  },
];

const prices_rus = [
  {
    service: "Подготовка к экзамену на G2 License",
    description1:
      "Включает практические уроки вождения и индивидуальные рекомендации.",
    description2:
      "Практика основных маневров и основных навыков вождения в городе, таких как параллельная парковка, разворот в три приема, движение задним ходом, парковка, а также соблюдение сигналов светофора и установленных ограничений скорости",
    currency: "$",
    price: prices_G2_G.g2,
    hour: "1 час",
  },
  {
    service: "Подготовка к экзамену на G License",
    description1:
      "Включает практические уроки вождения и индивидуальные рекомендации",
    description2:
      "Практика вождения в городе и на шоссе, включая въезд на автостраду, смену полосы движения и движение в высокоскоростном потоке движения",
    currency: "$",
    price: prices_G2_G.g,
    hour: "1 час",
  },
];

const prices_text_eng = [
  {
    text: [
      "Good preparation before taking the G2 and G road tests is essential as it significantly increases the likelihood of success and ensures a smoother driving experience. Proper preparation allows drivers to acquire and refine the necessary skills to handle diverse driving conditions with confidence.",
      "At Roman Driving School, we are dedicated to helping students achieve their driving goals and pass their road tests with success. Our expert instructors provide tailored training that addresses both basic and advanced driving skills required for the G2 and G license road tests. The duration and number of lessons are adaptable and customized to meet the individual needs of our students, and we do not provide unnecessary or excessive course packages.",
    ],
  },
];

const prices_text_rus = [
  {
    text: [
      "Ваш успех на экзаменах G2 и G начинается с качественной подготовки! Мы понимаем, как важна правильная подготовка для уверенного управления на дороге и успешной сдачи экзаменов по вождению. Также, эффективная подготовка к экзаменам G2 и G является ключевым фактором для достижения успеха и обеспечения комфортного управления транспортым средством. Правильное обучение помогает водителям развить и улучшить навыки, необходимые для уверенного вождения в любых дорожных условиях.",
      "В Roman Driving School мы ориентированы на помощь нашим студентам в достижении их целей и успешной сдаче экзаменов по вождению. Наши квалифицированные инструкторы предоставляют индивидуальные занятия, охватывающие как основные, так и более сложные навыки вождения, требуемые для получения прав G2 и G. Мы обучаем вождению, учитывая уникальные потребности каждого студента.",
    ],
  },
];

const priceTitle_eng = "Services and Prices";
const priceTitle_rus = "Наши услуги и цены";

const car_rental_eng =
  "The use of the driving instructor's vehicle for the road examination is available";

const car_rental_rus =
  "Предоставляем автомобиль инструктора для сдачи экзамена по вождению";

let exp = {
  prices_eng,
  prices_rus,
  car_rental_eng,
  car_rental_rus,
  priceTitle_eng,
  priceTitle_rus,
  prices_text_eng,
  prices_text_rus,
};

export default exp;
