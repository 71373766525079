const about_eng = [
  "Established in 1993, Roman Driving School has distinguished itself as a well-regarded driving school in Toronto and its surrounding areas, including North York, Richmond Hill, Mississauga, Brampton, and Etobicoke. Our school is renowned for its team of highly skilled instructors who are dedicated to delivering exceptional service. Whether you are a novice driver or an experienced driver seeking to refine your skills, Roman Driving School offers comprehensive lessons tailored to individual needs.",
  "Our services include thorough preparation and support for theoretical examinations, as well as specialized coaching for both G2 and G road tests. We emphasize personalized instruction to ensure each student receives focused attention and progresses optimally. As a leading driving school in Toronto, we are recognized for providing the highest quality driving lessons throughout the Greater Toronto Area (GTA). Our clients consistently report high levels of satisfaction with our instructors and commendable results in driving tests.",
  "Having demonstrated consistent success, ninety percent of our students pass their examinations on their initial attempt, highlighting the efficacy and quality of our educational approach. Whether your goal is to obtain a driver's license or enhance your driving abilities, Roman Driving School is dedicated to assisting you in achieving your objectives with confidence and proficiency. Join us today and experience why we are esteemed for excellence in driver education across Toronto and the GTA.",
];

const about_rus = [
  "Основанная в 1993 году, школа вождения Roman Driving School зарекомендовала себя как надежное учебное заведение в Торонто и его окрестностях, включая Норт-Йорк, Ричмонд-Хилл, Миссиссогу, Брамптон и Этобико. Наша школа отличается высококвалифицированными инструкторами, которые имеют большой профессиональный опыт по обучению вождению. Независимо от того, являетесь ли вы новичком или опытным водителем, стремящимся совершенствовать свои навыки, Roman Driving School предлагает комплексные занятия, индивидуально адаптированные к вашим потребностям.",
  "Наши услуги включают тщательную подготовку и поддержку теоретических экзаменов, а также специализированное обучение для экзаменов по вождению на категорию прав G2 и G. Мы делаем акцент на индивидуальном обучении, чтобы гарантировать, что каждый ученик получает необходимое внимание и достигает оптимального прогресса. Как ведущая автошкола в Торонто, мы предоставляем уроки вождения высочайшего качества по всему Большому Торонто (GTA) и наши клиенты положительно отзываются о высоком уровне наших инструкторов и результатах на экзаменах по вождению.",
  "Девяносто процентов наших студентов сдают экзамены на категорию прав G2 и G с первого раза, что демонстрируют эффективность и качество нашего образовательного подхода. Независимо от того, хотите ли вы получить водительские права или улучшить свои навыки вождения, Roman Driving School стремится помочь вам достичь ваших целей. Присоединяйтесь к нам сегодня и узнайте почему нас ценят за наши достижения в обучении вождению в Торонто и GTA.",
];

const aboutTitle_eng = "About Us";
const aboutTitle_rus = "О нас";

let exp = {
  about_eng,
  about_rus,
  aboutTitle_eng,
  aboutTitle_rus,
};

export default exp;
