import React from "react";
import Component2 from "../../Component2/Component2";
import Component1 from "../../Component1/Component1";

function Home() {
  return (
    <>
      {/* <Component2 ref1={scrollToTop} /> */}
      <Component1 />
      <Component2 />
    </>
  );
}

export default Home;
