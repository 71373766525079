import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer__area">
      <div className="footer__area-content">
        <div className="footer__area-content-title">Roman Driving School</div>
      </div>
      <address className="nap_container">
        <p className="name_footer">Roman Driving School</p>
        <p className="address_footer">
          6174 Yonge St, Unit 203 Toronto, ON M2M 3X1
        </p>
        <p className="phone_footer">+14167276997</p>
        <p className="phone_footer">+19057091044</p>
      </address>
    </div>
  );
};

export default Footer;
