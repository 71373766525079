import React, { useContext } from "react";
import { LangContext } from "../App";
import data from "../data/data.js";
import "./Services.css";

const ServicesOption = (props) => {
  return (
    <li className="services__option" id={"service_option_id_" + props.id}>
      {props.option}
    </li>
  );
};

const Services = () => {
  const { lang } = useContext(LangContext);
  let temp = 0;
  let temp_data;

  if (lang) {
    temp_data = data.data_eng;
  } else {
    temp_data = data.data_rus;
  }

  return (
    <div className="services__area">
      <div className="services__title">{temp_data.services_title}</div>
      <ul className="services__ul">
        {temp_data.services.map((el) => {
          temp++;
          return <ServicesOption key={temp} id={temp} option={el} />;
        })}
      </ul>
    </div>
  );
};

export default Services;
