import React, { useContext } from "react";
import LogoComponent from "../../ComponentPages/LogoComponent/LogoComponent";
import "./Prices.css";
import prices from "../../data/prices";
import dataForPhones from "../../data/data";
import { LangContext } from "../../App";

const PricesIntro = (props) => {
  return (
    <>
      {props.item.map((el, index) => {
        return (
          <div className="priceIntro_p_div" key={index}>
            <p
              id={`priceTextParagraph_id${props.id}`}
              className="priceTextParagraph_p"
            >
              {el}
            </p>
          </div>
        );
      })}
    </>
  );
};

const PriceComponent = (props) => {
  return (
    <div className="priceElement_div">
      <div className="priceElementTitlePrice">
        <div className="priceElementTitle bold ">{props.item.service}</div>
        <div className="priceElementPrice bold">
          <div className="corporate_red">
            {props.item.currency}
            {props.item.price}
          </div>
          <div className="hourInfo">{props.item.hour}</div>
        </div>
      </div>
      <div className="priceElementDescription">
        <div>{props.item.description1}</div>
        <div>{props.item.description2}</div>
      </div>
    </div>
  );
};

const RentalComponent = (props) => {
  return (
    <div className="pricesRental_div">
      <div className="pricesRentalText">{props.data.carOrder}</div>
      <div className="pricesPhones_div">
        <div className="pricesPhones bold">Call Us:</div>
        <div className="pricesPhones">{props.data.phones[0]}</div>
        <div className="pricesPhones">{props.data.phones[1]}</div>
      </div>
    </div>
  );
};

const ShowPrices = (props) => {
  const { lang } = useContext(LangContext);
  let temp_data, title, text, carOrder;
  let temp_id = 0;

  let phones = dataForPhones.data_eng.phones;

  if (lang) {
    temp_data = prices.prices_eng;
    title = prices.priceTitle_eng;
    text = prices.prices_text_eng;
    carOrder = prices.car_rental_eng;
  } else {
    temp_data = prices.prices_rus;
    title = prices.priceTitle_rus;
    text = prices.prices_text_rus;
    carOrder = prices.car_rental_rus;
  }

  return (
    <div className="pricesContainer">
      <div className="priceSectionTitle bold services__title">{title}</div>
      {text.map((item, index) => {
        temp_id++;

        return (
          <div className="pricesPlaceholder" key={index}>
            <PricesIntro key={temp_id} id={temp_id} item={item.text} />
          </div>
        );
      })}

      <div className="pricesListPlaceholder">
        {temp_data.map((item, index) => {
          temp_id++;
          return (
            <div className="pricesPlaceholder_prices" key={index}>
              <PriceComponent key={temp_id} id={temp_id} item={item} />
            </div>
          );
        })}
      </div>

      <RentalComponent text={prices.car_rental} data={{ phones, carOrder }} />
    </div>
  );
};

function Prices() {
  return (
    <div>
      <LogoComponent />
      <ShowPrices />
    </div>
  );
}

export default Prices;
