import React, { useContext, useRef } from "react";
import { LangContext } from "../App";
import { GiSmartphone } from "react-icons/gi";
import { MdEmail } from "react-icons/md";
import data from "../data/data.js";
import "./ContactUs.css";

const ShowAddress = (props) => {
  return (
    <address className="address_div">
      <div
        className="contactUs__address"
        id={"contactUs__address_id" + props.id}
      >
        <div className="contactUs__address-p1">{props.address.address}</div>
        <div className="contactUs__address-p2">
          <div className="contactUs__address-city">
            {props.address.city}
            {", "}
          </div>
          <div className="contactUs__address-province">
            {props.address.province}{" "}
          </div>
          <div className="contactUs__address-zipCode">
            {props.address.zipCode}
          </div>
        </div>
      </div>
    </address>
  );
};

const ContactUs = () => {
  const scrollToServices_con = useRef(null);

  const { lang } = useContext(LangContext);
  let temp_data;

  if (lang) {
    temp_data = data.data_eng;
  } else {
    temp_data = data.data_rus;
  }

  return (
    <div className="contactUs__area">
      <div className="contactUs__area_div">
        <div className="contactUs__title" ref={scrollToServices_con}>
          {temp_data.contacts}
        </div>
        <div className="contactUs__address-area">
          <ShowAddress address={temp_data.addresses[0]} id={1} />
        </div>

        <div className="contactUs__contacts">
          <div className="contactUs__contacts-phones_div">
            <div className="contactUs__contacts-phones_iconArea">
              <div>
                <GiSmartphone color="rgb(189, 30, 56)" />
              </div>
            </div>
            <div className="contactUs__contacts-phonesArea">
              <div className="contactUs__contacts-phones1">
                {temp_data.phones[0]}
              </div>
              <div className="contactUs__contacts-phones2">
                {temp_data.phones[1]}
              </div>
            </div>
          </div>
          <div className="contactUs__contacts-email_div">
            <div className="contactUs__contacts-email_iconArea">
              <MdEmail color="rgb(189, 30, 56)" id={"email_icon"} />
            </div>
            <div className="contactUs__contacts-email">{temp_data.email}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
