import React, { useContext } from "react";
import "./Component1.css";
import data from "../data/data.js";
import { LangContext } from "../App";

const ShowMap = (props) => {
  return (
    <div className="contactUs__map" id={"contactUs__map" + props.id}>
      <div className="contactUs__map-p1">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2880.0211834260267!2d-79.42146701279984!3d43.7931734747043!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b3277717da71d%3A0xeddd994788437ffd!2sRoman%20Driving%20School!5e0!3m2!1sen!2sca!4v1685111956020!5m2!1sen!2sca"
          width="100"
          height="50"
          allowfullscreen=""
          loading="eager"
          referrerpolicy="no-referrer-when-downgrade"
          id="mapI_frame_id"
          title="map_title"
        ></iframe>
      </div>
    </div>
  );
};

const ShowArea = (props) => {
  return (
    <div className="component__main-areas-div">
      <div
        className="component__main-areas-dash"
        id={`component__main-areas-dash_id${props.id}`}
      >
        -
      </div>
      <div className="component__main-areas-name">{props.area}</div>
    </div>
  );
};

const ShowBusinessHours = (props) => {
  if (props.id !== 1) {
    return (
      <div className="component__main-businessHours_div">
        <div className="component__main-businessHours-data">{props.hours}</div>
      </div>
    );
  }
};

const Component1 = () => {
  const { lang } = useContext(LangContext);
  let temp_id = 0,
    temp_id_bh = 0;
  let temp_data;

  if (lang) {
    temp_data = data.data_eng;
  } else {
    temp_data = data.data_rus;
  }

  return (
    <div className="component__main">
      <div className="name_div">
        <h1>Roman Driving School</h1>
        <h2>6174 Yonge St, Unit 203 Toronto, ON M2M 3X1</h2>
        <h2>best driving school in Toronto</h2>
        <h2>best driving school in Russian</h2>
        <h2>6174 Yonge St, Unit 203</h2>
        <h2>Toronto, ON</h2>
        <h2>M2M 3X1</h2>
        <h2>+14167276997</h2>
        <h2>+19057091044</h2>
        <address>6174 Yonge St, Unit 203 Toronto, ON M2M 3X1</address>
        <h2>Russian driving school</h2>

        <h2>Russian driving school in Toronto</h2>
        <h2>Toronto Russian driving school</h2>
        <h2>driving school in Russian</h2>
        <h2>driving school in Toronto</h2>

        <h2>best Toronto driving school in Russian</h2>
        <h2>best driving school in Toronto</h2>
        <h2>Toronto best driving school</h2>
        <h2>Toronto best driving school in Russian</h2>
        <h2>driving school Russian language</h2>

        <h2>driving school Toronto North York</h2>
        <h2>driving school North York Toronto</h2>
        <h2>driving school Toronto Yonge</h2>
        <h2>driving school Yonge Toronto</h2>

        <h2>
          We teach in Russian, Ukrainian, Polish, Hebrew, Slovak, Hungarian
        </h2>
        <h2>Lessons on new electric cars</h2>
        <h2>Over 30 years of teaching experience</h2>
        <h2>North York, Richmond Hill, Mississauga, Brampton</h2>

        <h2>roman driving school in Toronto</h2>
        <h2>Toronto driving school</h2>
        <h2>Toronto driving school in Russian</h2>
        <h2>Toronto driving school in Russian language</h2>

        <h2>Lessons for beginning and skilled drivers</h2>
        <h2>Support and preparation for theoretical examination</h2>
        <h2>
          Reception of the certificate lowering the insurance and term of
          expectation of examination on driving
        </h2>
        <h2>Individual lessons of 60-90 minutes</h2>
        <h2>
          Lessons on Highways - 90 minutes with display of routes on the Highway
        </h2>
        <h2>Display of examination routes &#40;on day of examination&#41;</h2>
        <h2>
          Time of examination in the shortest term depends on the readiness of
          the student
        </h2>
        <h2>
          The help in purchase of the car and the insurance under reasonable
          prices
        </h2>

        {/* <h2>Roman</h2>
        <h2>Driving</h2>
        <h2>School</h2>
        <h2>Toronto</h2>
        <h2>North York</h2>
        <h2>Richmond Hill</h2>
        <h2>Mississauga</h2>
        <h2>Brampton</h2>
        <h2>Etobicoke</h2>
        <h2>Ontario</h2>
        <h2>Lessons</h2>
        <h2>drivers</h2>
        <h2>Russian</h2>
        <h2>Ukrainian</h2>
        <h2>Polish</h2>
        <h2>Hebrew</h2>
        <h2>Slovak</h2>
        <h2>Hungarian</h2>
        <h2>electric cars</h2>
        <h2>driving school</h2>
        <h2>experienced instructors</h2> */}

        <div className="map_div">
          <ShowMap />
        </div>
      </div>
      <div className="component__main-logoArea">
        <img src={data.logo} id="logo_id" alt="Logo" />
      </div>
      <div className="component__main-areas">
        {data.data_eng.areas.map((area) => {
          temp_id++;
          return <ShowArea key={temp_id} id={temp_id} area={area} />;
        })}
      </div>
      <div
        className="component__main-businessHours"
        id="component__main-businessHours_id"
      >
        <div className="component__main-businessHours-title">
          {temp_data.business_hours[0]}
        </div>
        {temp_data.business_hours.map((el, index) => {
          temp_id_bh++;
          return <ShowBusinessHours key={index} id={temp_id_bh} hours={el} />;
        })}
      </div>
      <div className="component__main-languagesArea">
        <div className="component__main-languagesArea-title">
          {temp_data.languages[0]}
        </div>
        <div className="component__main-languagesArea-data">
          {temp_data.languages[1]}
        </div>
      </div>
      <div className="component__main-promotionsArea">
        <div className="component__main-promotions">
          {temp_data.promotions[0]}
        </div>
        <div className="component__main-promotions">
          {temp_data.promotions[1]}
        </div>
      </div>
    </div>
  );
};

export default Component1;
