import React, { useContext } from "react";
import LogoComponent from "../../ComponentPages/LogoComponent/LogoComponent";
import "./About.css";
import about from "../../data/about";
import { LangContext } from "../../App";

const AboutIntro = (props) => {
  return (
    <>
      <p className="priceTextParagraph_p">{props.item}</p>
    </>
  );
};

const ShowAboutUs = (props) => {
  const { lang } = useContext(LangContext);
  let temp_data, title;
  let temp_id = 0;

  if (lang) {
    temp_data = about.about_eng;
    title = about.aboutTitle_eng;
  } else {
    temp_data = about.about_rus;
    title = about.aboutTitle_rus;
  }

  return (
    <div className="pricesContainer">
      <div className="priceSectionTitle bold services__title">{title}</div>
      {temp_data.map((item) => {
        temp_id++;

        return (
          <div className="pricesPlaceholder">
            <AboutIntro key={temp_id} id={temp_id} item={item} />
          </div>
        );
      })}
    </div>
  );
};

function About() {
  return (
    <div>
      <LogoComponent />
      <ShowAboutUs />
    </div>
  );
}

export default About;
