import React from "react";
import data from "../data/data.js";
import "./CarImage.css";

const Component2 = () => {
  return (
    <div className="carImage_div">
      <img
        src={data.carImage}
        id="carImage_id"
        alt="Roman Driving School, Toronto, Ontario"
      />
    </div>
  );
};

export default Component2;
