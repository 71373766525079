import Services from "../Services/Services";
import CarImage from "../CarImage/CarImage";
import ContactUs from "../ContactUs/ContactUs";

import Reviews from "../Reviews/Reviews";
import "./Component2.css";

const Component2 = ({ ref1 }) => {
  return (
    <div className="component2__container">
      <div className="component2__mainComponents">
        <Services />
        <CarImage />
        <Reviews />
        <ContactUs />
      </div>
    </div>
  );
};

export default Component2;
