import React, { useContext, forwardRef } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import data from "../data/data.js";
import { LangContext } from "../App";

const Header = forwardRef((props, scrollToTop) => {
  const { lang, setLang } = useContext(LangContext);

  let temp_data, links;

  function changeDataSource() {
    if (lang) {
      temp_data = data.data_eng;
      links = data.links_data[0];
    } else {
      temp_data = data.data_rus;
      links = data.links_data[1];
    }
  }

  function toggleLangFunc(e) {
    setLang((prevLang) => !prevLang);
    changeDataSource();
  }

  changeDataSource();

  return (
    <nav className="app__navbar" id="app__navbar_id" ref={scrollToTop}>
      <ul className="app__navbar-options">
        <li className="header__call-li">
          <span className="header__call-text">{temp_data.phones_title}</span>
        </li>
        <li className="header__call-li">{temp_data.phones[0]},</li>
        <li className="header__call-li">{temp_data.phones[1]}</li>
      </ul>

      <div className="links_Container">
        <Link to="/" className="text-uppercase navBarLinkStyle">
          {" "}
          {links.home}
        </Link>
        <Link to="/prices" className="text-uppercase navBarLinkStyle">
          {" "}
          {links.prices}
        </Link>
        <Link to="/about" className="text-uppercase navBarLinkStyle">
          {" "}
          {links.aboutUs}
        </Link>
      </div>

      <button
        className="header__call-button"
        type="button"
        onClick={toggleLangFunc}
      >
        {temp_data.next_lang}
      </button>
    </nav>
  );
});

export default Header;
