import React from "react";
import logoData from "../../data/data";
import "./LogoComponent.css";

function LogoComponent() {
  return (
    <div className="component__main">
      <div className="component__main-logoArea">
        <img src={logoData.logo} id="logo2_id" alt="Logo" />
      </div>
    </div>
  );
}

export default LogoComponent;
