import React, { useState, useMemo, useRef, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import Header from "./Header/Header";
import Footer from "./Footer/Footer";

import Home from "./Pages/Home/Home";
import Prices from "./Pages/Prices/Prices";
import About from "./Pages/About/About";

import "./App.css";

export const LangContext = React.createContext();

function App() {
  const [lang, setLang] = useState(true);
  const scrollOnload = useRef(null);
  const scrollToTop = useRef(null);

  const lang_value = useMemo(() => ({ lang, setLang }), [lang]);

  useEffect(() => {
    scrollOnload.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <LangContext.Provider value={lang_value}>
      {useMemo(
        () => (
          <div className="app__main_div" ref={scrollOnload}>
            <Header scrollToTop={scrollToTop} />

            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/prices" element={<Prices />} />
              <Route path="/about" element={<About />} /> */}
            </Routes>

            <Footer />
          </div>
        ),
        []
      )}
    </LangContext.Provider>
  );
}

export default App;
